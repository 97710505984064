import { Typography, Divider, Button } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const Headline = ({ activeStep }) => (
  <>
    {activeStep.headline && (
      <>
        <Typography variant="h5" gutterBottom>
          {activeStep.title || activeStep.label}
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ fontWeight: 500 }}
        >
          {activeStep.headline}
        </Typography>
        {activeStep.videoUrl && (
          <>
            <Divider />
            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              <iframe
                id="ytplayer"
                title={activeStep.videoUrl}
                type="text/html"
                width="500"
                height="230"
                style={{ maxWidth: "100%" }}
                src={activeStep.videoUrl}
                frameBorder="0"
              />
            </div>
          </>
        )}
        {activeStep.quizUrl && (
          <Button
            style={{ marginBottom: 12 }}
            variant="outlined"
            color="primary"
            href={activeStep.quizUrl}
            size="large"
            target="_blank"
            rel="noopener noreferrer"
            startIcon={<PlayArrowIcon />}
          >
            Try Our Sample Questions on {activeStep.caption}
          </Button>
        )}
        <Divider />
      </>
    )}
  </>
);

export default Headline;
