import { Typography, Link } from "@material-ui/core";

const Sources = ({ activeStep }) => (
  <>
    {activeStep.sources && (
      <div style={{ marginTop: 12 }}>
        <Typography variant="caption">
          Sources:{" "}
          {activeStep.sources.map((src, index) => (
            <span key={src.url}>
              {index !== 0 && <span>, </span>}
              <Link
                href={src.url}
                style={{
                  wordBreak: "break-word",
                }}
                target="_blank"
                rel="noopener noreferrer"
              >
                {src.name}
              </Link>
            </span>
          ))}
        </Typography>
      </div>
    )}
  </>
);

export default Sources;
