import { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Grid,
  useMediaQuery,
  Button,
} from "@material-ui/core";
import Header from "./Header";
import Stepper from "./Stepper";
import Summary from "./Summary";
import Headline from "./Headline";
import Body from "./Body";
import Sources from "./Sources";
import Notes from "./Notes";
import Sidebar from "./Sidebar";
import NavButtons from "./NavButtons";
import steps from "./steps";
import defaultValues from "./defaultValues";
import validateFunc from "./validate";
import { tokenize, detokenize } from "./utils/stateToken";
import URI from "urijs";

const replaceToken = (token) => {
  const currentUrl = URI(window.location.href);
  const newUrl = currentUrl
    .query({ ...currentUrl.search(true), token })
    .toString();

  window.history.replaceState({}, "", newUrl);
};

const Calculator = ({ token }) => {
  let defaultState = (token && detokenize(token)) || defaultValues;

  if (!defaultState.version) {
    defaultState = {
      ...defaultState,
      surpriseBills: defaultValues.surpriseBills,
    };
  }

  const [values, setValues] = useState(defaultState);

  useEffect(() => {
    const token = tokenize(values);
    replaceToken(token);
  }, [values]);

  const [activeStepIndex, _setActiveStepIndex] = useState(0);
  const [errors, setErrors] = useState([]);

  const activeStep = steps[activeStepIndex];

  const validate = () => validateFunc(activeStep, values, setErrors);

  const setActiveStepIndex = (value) => {
    if (validate()) {
      _setActiveStepIndex(value);
    }
  };

  const handleNext = () => {
    setActiveStepIndex((prevactiveStepIndex) => prevactiveStepIndex + 1);
  };

  const handleBack = () => {
    setActiveStepIndex((prevactiveStepIndex) => prevactiveStepIndex - 1);
  };

  const isSmallScreen = useMediaQuery("(max-width:800px)");

  return (
    <div style={{ maxWidth: 1200, marginLeft: "auto", marginRight: "auto" }}>
      <Card>
        <Header
          activeStepIndex={activeStepIndex}
          setActiveStepIndex={setActiveStepIndex}
          values={values}
        />
        <Grid container>
          <Grid item xs={12} md={3}>
            <Sidebar />
          </Grid>
          <Grid item xs={12} md={9}>
            <CardContent>
              <Grid container>
                <Grid item>
                  <Stepper
                    activeStepIndex={activeStepIndex}
                    setActiveStepIndex={setActiveStepIndex}
                    activeStep={activeStep}
                    values={values}
                    setValues={setValues}
                    errors={errors}
                    validate={validate}
                    navButtons={
                      <NavButtons
                        activeStepIndex={activeStepIndex}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        spacing
                      />
                    }
                    isSmallScreen={isSmallScreen}
                  />
                </Grid>
                {!isSmallScreen && (
                  <Grid item style={{ flex: 1, paddingLeft: 12 }}>
                    {activeStep.type === "summary" ? (
                      <Summary
                        token={tokenize(values)}
                        {...values}
                        setActiveStepIndex={setActiveStepIndex}
                      />
                    ) : (
                      <>
                        <Headline activeStep={activeStep} />
                        <Grid container spacing={2} style={{ paddingTop: 12 }}>
                          <Body
                            activeStep={activeStep}
                            values={values}
                            setValues={setValues}
                            errors={errors}
                            validate={validate}
                          />
                        </Grid>
                      </>
                    )}
                    <Notes activeStep={activeStep} />
                    <Sources activeStep={activeStep} />
                  </Grid>
                )}
              </Grid>
            </CardContent>

            <>
              <Divider />
              <CardActions>
                <Button onClick={() => setValues(defaultValues)}>Reset</Button>

                {!isSmallScreen && (
                  <NavButtons
                    activeStepIndex={activeStepIndex}
                    handleBack={handleBack}
                    handleNext={handleNext}
                  />
                )}
              </CardActions>
            </>
          </Grid>
        </Grid>
      </Card>
    </div>
  );
};

export default Calculator;
