import { useEffect } from "react";
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  StepButton,
  StepContent,
  Grid,
} from "@material-ui/core";
import scrollIntoView from "scroll-into-view-if-needed";
import steps from "./steps";
import Summary from "./Summary";
import Headline from "./Headline";
import Body from "./Body";
import Sources from "./Sources";
import Notes from "./Notes";

const Stepper = ({
  activeStepIndex,
  setActiveStepIndex,
  isSmallScreen,
  navButtons,
  activeStep,
  values,
  setValues,
  errors,
  validate,
}) => {
  useEffect(() => {
    const node = document.getElementById(`step-${activeStepIndex}`);

    scrollIntoView(node, {
      scrollMode: "if-needed",
      block: "nearest",
      inline: "nearest",
    });
  }, [activeStepIndex]);

  return (
    <MuiStepper activeStep={activeStepIndex} orientation="vertical" nonLinear>
      {steps.map((step, index) => (
        <Step id={`step-${activeStepIndex}`} key={step.label}>
          <StepButton onClick={() => setActiveStepIndex(index)}>
            <StepLabel>{step.label}</StepLabel>
          </StepButton>
          {isSmallScreen && (
            <StepContent style={{ paddingTop: 10 }}>
              {activeStep.type === "summary" ? (
                <Summary {...values} setActiveStepIndex={setActiveStepIndex} />
              ) : (
                <>
                  <Headline activeStep={activeStep} />
                  <Grid container spacing={2} style={{ paddingTop: 12 }}>
                    <Body
                      activeStep={activeStep}
                      values={values}
                      setValues={setValues}
                      errors={errors}
                      validate={validate}
                    />
                  </Grid>
                </>
              )}
              <Notes activeStep={activeStep} />
              <Sources activeStep={activeStep} /> <div style={{ height: 20 }} />
              {navButtons}
            </StepContent>
          )}
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
