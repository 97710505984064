import copyToClipboard from "copy-to-clipboard";

export const shareViaEmail = (url) => {
  window.location.href = `mailto:?subject=Quizzify ROI&body=${encodeURIComponent(
    `See our customized ROI from using Quizzify.com: ${url}`
  )}`;
};

export const shareViaClipboard = (url) => {
  copyToClipboard(url);
};
