import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
} from "@material-ui/core/styles";
import Calculator from "./Calculator";
import muiTheme from "./mui-theme";
import URI from 'urijs'

const theme = createMuiTheme(muiTheme);

const App = () => {
  const url = URI(window.location.href);
  const token = url.search(true)["token"];

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Calculator token={token} />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
