import { Typography } from "@material-ui/core";

const Notes = ({ activeStep }) => (
  <>
    {activeStep.notes && (
      <div style={{ marginTop: 12 }}>
        <Typography variant="caption">Notes: {activeStep.notes}</Typography>
      </div>
    )}
  </>
);

export default Notes;
