import base64 from 'base-64';
import utf8 from 'utf8';

export const tokenize = values => {
  const dump = JSON.stringify(values);
  const utfed = utf8.encode(dump);
  const tokened = base64.encode(utfed);

  return tokened;
}

export const detokenize = token => {
  try {
    const utfed = base64.decode(token);
    const dump = utf8.encode(utfed);
    const values = JSON.parse(dump);

    return values;
  } catch {
    return undefined;
  }
}
