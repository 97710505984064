import _ from "lodash";
import steps from "./steps";

export const AUTOMATED_STEP_START = 1;

export const automatedSteps = steps.slice(AUTOMATED_STEP_START, -1);

export const getValue = (values, value, calculations, index, debug) => {
  const { formula } = value;

  const total = formula.reduce((acc, cur) => {
    const priorValue = cur.prior
      ? getValue(
          values,
          _.get(calculations, `[${index - cur.prior}]`, {}).value,
          calculations,
          index - cur.prior
        )
      : null;

    let currentValue = Number(
      priorValue || _.get(values, cur.path) || cur.value
    );

    if (isNaN(currentValue) && priorValue === 0) {
      currentValue = 0;
    }

    if (cur.first) {
      return acc + currentValue;
    }

    if (cur.operator === "add") {
      return acc + currentValue;
    }

    if (cur.operator === "subtract") {
      return acc - currentValue;
    }

    if (cur.operator === "multiply") {
      let num = acc * currentValue;
      if (cur.unit === "%") {
        num /= 100;
      }
      if (cur.unit === "per thousand") {
        num /= 1000;
      }
      return num;
    }

    if (cur.operator === "divide") {
      return acc / currentValue;
    }

    return acc;
  }, 0);

  return total;
};

const calc = (values) => {
  const {
    numPeople,
    quizzifyCostPmpy,

    avgCostSdf,
  } = values;

  const getTotal = (step) => {
    const { calculations } = step;
    const lastIndex = calculations?.length - 1;
    const lastCalculationValue = calculations
      ? calculations[lastIndex].value
      : null;

    return getValue(values, lastCalculationValue, calculations, lastIndex);
  };

  const totalSavings = automatedSteps.reduce(
    (acc, cur) => acc + getTotal(cur),
    0
  );

  const qCost = numPeople * quizzifyCostPmpy;

  const roi = (totalSavings / qCost).toFixed(1);

  const isValidRoi = roi !== "Infinity" && !isNaN(roi);

  return {
    roi,
    isValidRoi,
    qCost,
    avgCostSdf,
    totalSavings,
    getTotal,
  };
};

export default calc;
