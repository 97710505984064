const validate = (activeStep, values, setErrors) => {
  if (activeStep.fields) {
    const fieldNames = activeStep.fields.map((f) => f.name);

    const { categoryKey } = activeStep;
    const targetValues =
      categoryKey && values[categoryKey] ? values[categoryKey] : values;

    const valueNames = Object.keys(targetValues);
    const valueNamesInScope = valueNames.filter((name) =>
      fieldNames.includes(name)
    );

    const fieldsWithErrors = valueNamesInScope.filter(
      (name) => !targetValues[name]
    );

    setErrors(fieldsWithErrors);

    return fieldsWithErrors.length === 0;
  }

  setErrors([]);

  return true;
};

export default validate;
