import NumberFormat from "react-number-format";

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowNegative={false}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
};

export const NumberInput = (props) => <NumberFormatCustom {...props} />;

export const DollarInput = (props) => (
  <NumberFormatCustom {...props} prefix="$" />
);

export const PercentInput = (props) => (
  <NumberFormatCustom {...props} suffix="%" />
);

export default NumberFormatCustom;
