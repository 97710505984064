import { Typography, Button, CircularProgress } from "@material-ui/core";
import calc from "./calc";

const RoiScoreboard = ({ onClick, values }) => {
  const { isValidRoi, roi } = calc(values);

  return (
    <Button
      onClick={onClick}
      style={{
        marginLeft: "auto",
        textTransform: "none",
        width: 83,
        height: 60,
      }}
      color="secondary"
      variant="contained"
    >
      <span>
        <Typography style={{ fontSize: "0.8em" }}>Est. ROI:</Typography>
        {isValidRoi ? (
          <Typography variant="h6">
            {roi > 100 ? ">100x" : `${roi}x`}
          </Typography>
        ) : (
          <CircularProgress size={20} style={{ marginTop: 5 }} />
        )}
      </span>
    </Button>
  );
};

export default RoiScoreboard;
