const defaultValues = {
  version: 2.0,
  companyName: "Vandelay Industries",
  numPeople: 1000,
  quizzifyCostPmpy: 12,
  avgCostERVisit: 2032,
  erVisitsPerThousand: 180,
  erReduction: 3,
  avgCostUrgentCare: 193,
  urgentCareVisitsPerThousand: 400,
  avgCostCTScan: 1200,
  ctScansPerThousand: 180,
  ctScanReduction: 7,
  avgCostMRI: 1700,
  mrisPerThousand: 95,
  mriReduction: 4.0,
  avgCostSpinalFusion: 51741,
  spinalFusionsPerThousand: 4.9,
  spinalFusionReduction: 5,
  avgCostStent: 32000,
  stentsPerThousand: 3,
  stentsReduction: 3,
  avgCostCavity: 150,
  cavitiesPerThousand: 300,
  cavitiesReduction: 10,
  avgCostSdf: 30,
  substitutionRateSdf: 1.1,
  prematureBirths: {
    birthRatePerc: 1,
    prematureBirthRatePerc: 10,
    costPer: 50000,
    reduction: 10,
  },
  surpriseBills: {
    erVisitRate: 180,
    currentCost: 1800,
    ourCost: 800,
    successRate: 10,
  },
};

export default defaultValues;
