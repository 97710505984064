import { Typography, Divider } from "@material-ui/core";

const BodySection = ({ f }) => {
  if (f.type === "sectionHeader")
    return (
      <>
        <Typography variant="h6">{f.text}</Typography>
        {f.subtext && <Typography variant="subtitle2">{f.subtext}</Typography>}
      </>
    );
  if (f.type === "divider") return <Divider />;

  return null;
};

export default BodySection;
