import {
  Typography,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Link,
  Button,
  useMediaQuery,
  Grid,
  Box,
} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import NumberFormat from "react-number-format";
import calc, { automatedSteps, AUTOMATED_STEP_START } from "./calc";
import { shortenApi } from "./api";
import { shareViaEmail, shareViaClipboard } from "./sharing";

const Summary = ({ token, companyName, setActiveStepIndex, ...rest }) => {
  const { qCost, roi, getTotal, totalSavings } = calc(rest);

  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const handleShareClick = () => {
    shortenApi(token).then(shareViaEmail);
  };
  const handleCopyClick = () => {
    shortenApi(token).then(shareViaClipboard);
  };

  return (
    <div
      style={{
        paddingTop: 12,
        width: "100%",
        paddingRight: 12,
        boxSizing: "border-box",
      }}
    >
      <Typography variant="h6" gutterBottom align="center">
        {companyName} ROI from Quizzify:
      </Typography>
      <Typography variant="h2" align="center">
        <NumberFormat
          value={roi}
          displayType={"text"}
          thousandSeparator={true}
          suffix={"x"}
        />
      </Typography>
      <Box p={1}>
        <Grid container spacing={1} justify="center" alignItems="center">
          <Grid item>
            <Typography variant="caption">Share ROI:</Typography>
          </Grid>
          <Grid item>
            <Button
              startIcon={<EmailIcon />}
              type="button"
              size="small"
              variant="outlined"
              onClick={handleShareClick}
            >
              Email
            </Button>
          </Grid>
          <Grid item>
            <Button
              startIcon={<FileCopyIcon />}
              size="small"
              type="button"
              variant="outlined"
              onClick={handleCopyClick}
            >
              Copy Link
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <TableContainer style={{ marginTop: 20 }}>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell style={{ fontSize: "1.1em" }}>
                Total Quizzify Cost
              </TableCell>
              <TableCell style={{ fontSize: "1.1em" }}>
                <NumberFormat
                  value={Math.round(qCost)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              </TableCell>
              {!isSmallScreen && (
                <TableCell>
                  <Link href="#" onClick={() => setActiveStepIndex(0)}>
                    Details
                  </Link>
                </TableCell>
              )}
            </TableRow>

            <TableRow>
              <TableCell style={{ fontSize: "1.1em" }}>Total Savings</TableCell>
              <TableCell style={{ fontSize: "1.1em" }}>
                <NumberFormat
                  value={Math.round(totalSavings)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix="$"
                />
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            {automatedSteps.map((step, index) => {
              return (
                <TableRow>
                  <TableCell style={{ paddingLeft: 35 }}>
                    {step.label}
                  </TableCell>
                  <TableCell>
                    <NumberFormat
                      value={Math.round(getTotal(step))}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix="$"
                    />
                  </TableCell>
                  {!isSmallScreen && (
                    <TableCell>
                      <Link
                        href="#"
                        onClick={() =>
                          setActiveStepIndex(index + AUTOMATED_STEP_START)
                        }
                      >
                        Details
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Summary;
