import { Typography, makeStyles, useMediaQuery } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  sidebar: {
    backgroundColor: theme.palette.grey[100],
    height: "100%",
    padding: 16,
    boxSizing: "border-box",
    textAlign: "center",
  },
}));

const Sidebar = () => {
  const classes = useStyles();

  const isSmallScreen = useMediaQuery("(max-width:960px)");

  return (
    <div className={classes.sidebar}>
      <Typography variant="subtitle1" align="left">
        Quizzify's ROI calculator helps you estimate how much you can save when
        your employees avoid unnecessary procedures or choose lower cost
        alternatives.
      </Typography>

      {!isSmallScreen && (
        <a
          href="https://validationinstitute.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/vi-logo.jpg"
            width="200"
            style={{ height: "auto", maxWidth: "100%", marginTop: 36 }}
            alt="Validation Institute Logo"
          />
        </a>
      )}
    </div>
  );
};

export default Sidebar;
