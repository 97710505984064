import { Typography, TextField, Grid } from "@material-ui/core";
import { DollarInput, PercentInput, NumberInput } from "./NumberFormatInput";
import BodySection from "./BodySection";
import Calculations from "./Calculations";

const Body = ({ values, activeStep, errors, setValues, validate }) => (
  <>
    {activeStep.fields.map((f, index) => {
      // Having a name indicates a field
      if (!!f.name) {
        const InputProps = {};

        if (f.specialInput === "$") {
          InputProps.inputComponent = DollarInput;
        } else if (f.specialInput === "%") {
          InputProps.inputComponent = PercentInput;
        } else if (f.specialInput === "number") {
          InputProps.inputComponent = NumberInput;
        }

        const { categoryKey } = activeStep;

        return (
          <Grid key={f.name} item xs={12} sm={f.width || 12}>
            <Typography variant="h6" style={{ fontSize: 16 }} gutterBottom>
              {f.headline}
            </Typography>

            <TextField
              name={f.name}
              value={
                categoryKey &&
                values[categoryKey] &&
                values[categoryKey][f.name]
                  ? values[categoryKey][f.name]
                  : values[f.name]
              }
              onChange={(e) => {
                if (errors.includes(f.name)) {
                  validate();
                }

                if (categoryKey) {
                  const updatedCategory = {
                    ...values[categoryKey],
                    [f.name]: e.target.value,
                  };

                  setValues({
                    ...values,
                    [categoryKey]: updatedCategory,
                  });
                } else {
                  setValues({
                    ...values,
                    [f.name]: e.target.value,
                  });
                }
              }}
              error={errors.includes(f.name)}
              helperText={errors.includes(f.name) && "Required"}
              size="medium"
              InputProps={InputProps}
              onFocus={(event) => event.target.select()}
              autoFocus={index === 0}
              fullWidth
              variant="outlined"
            />
          </Grid>
        );
      }

      return (
        <Grid key={f.key} item xs={12}>
          <BodySection f={f} values={values} />
        </Grid>
      );
    })}

    <Calculations values={values} calculations={activeStep.calculations} />
  </>
);

export default Body;
