import { Button } from "@material-ui/core";
import steps from "./steps";

const NavButtons = ({ activeStepIndex, handleBack, handleNext, spacing }) => (
  <>
    <div style={{ flex: 1 }} />
    <Button
      disabled={activeStepIndex === 0}
      onClick={handleBack}
      variant="outlined"
      style={{ marginRight: spacing ? 12 : 0 }}
    >
      Back
    </Button>
    {activeStepIndex !== steps.length - 1 && (
      <Button variant="contained" color="secondary" onClick={handleNext}>
        {activeStepIndex === steps.length - 2 ? "Finish" : "Next"}
      </Button>
    )}
  </>
);

export default NavButtons;
