import { AppBar, Toolbar, Typography } from "@material-ui/core";
import RoiScoreboard from "./RoiScoreboard";
import steps from "./steps";

const ROI_STEP = steps.length - 1;

const Header = ({ activeStepIndex, values, setActiveStepIndex }) => (
  <AppBar position="static" color="primary">
    <Toolbar>
      <Typography
        variant="h5"
        style={{ fontFamily: "didot", fontWeight: "bold" }}
      >
        Quizzify
      </Typography>
      <span style={{ width: 20 }} />
      <Typography variant="h6" style={{ fontSize: 18, fontWeight: "bold" }}>
        ROI Calculator
      </Typography>
      {activeStepIndex !== ROI_STEP && (
        <RoiScoreboard
          onClick={() => setActiveStepIndex(ROI_STEP)}
          values={values}
        />
      )}
    </Toolbar>
  </AppBar>
);

export default Header;
