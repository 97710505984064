const steps = [
  {
    type: "form",
    label: "Getting Started",
    title: "Getting Started",
    headline: "Let's start calculating your ROI with some basic company info:",
    videoUrl: "https://youtube.com/embed/Zg0hmEPbg-I",
    fields: [
      {
        headline: "What is your company or organization's name?",
        name: "companyName",
      },
      {
        headline: "How many people do you have covered?",
        name: "numPeople",
        specialInput: "number",
      },
      {
        headline: "Quizzify cost per member per year",
        name: "quizzifyCostPmpy",
        specialInput: "$",
      },
    ],
    calculations: [
      {
        type: "total",
        label: "Total Cost",
        value: {
          formula: [
            {
              first: true,
              operator: "multiply",
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "quizzifyCostPmpy",
              unit: "$",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Population x Quizzify $PMPY",
      },
    ],
  },
  {
    type: "form",
    label: "Cavities",
    headline:
      "Drilling cavities is by far the single most common procedure in all of healthcare...and by far the most inappropriate for 2/3 of cavities.",
    videoUrl: "https://youtube.com/embed/jN6R4By3YJE",
    caption: "cavities",
    quizUrl: "https://play.quizzify.com/quiz/5355",
    fields: [
      {
        type: "sectionHeader",
        text: "Cavity Fillings",
      },
      {
        headline: "Events per 1,000",
        name: "cavitiesPerThousand",
        specialInput: "number",
        width: 4,
      },
      {
        headline: "Average cost",
        name: "avgCostCavity",
        specialInput: "$",
        width: 4,
      },
      {
        headline: "Quizzify Reduction",
        name: "cavitiesReduction",
        specialInput: "%",
        width: 4,
      },
      {
        type: "divider",
        key: "cavities-divider",
      },
      {
        type: "sectionHeader",
        text: "Silver Diamine Fluoride (SDF)",
      },
      {
        headline: "Average cost",
        name: "avgCostSdf",
        specialInput: "$",
        width: 6,
      },
      {
        headline: "Substitution Rate",
        name: "substitutionRateSdf",
        specialInput: "number",
        width: 6,
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Baseline Fillings",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "cavitiesPerThousand",
              unit: "per thousand",
            },
          ],
        },
        workCaption: "Population x Rate",
      },
      {
        type: "lineItem",
        label: "Savings on Fillings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
            },
            {
              operator: "multiply",
              path: "avgCostCavity",
              unit: "$",
            },
            {
              operator: "multiply",
              path: "cavitiesReduction",
              unit: "%",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Fillings x Cost x Reduction",
      },
      {
        type: "lineItem",
        label: "Cost of SDF Substitution",
        value: {
          formula: [
            {
              first: true,
              prior: 2,
            },
            {
              operator: "multiply",
              path: "cavitiesReduction",
              unit: "%",
            },
            {
              operator: "multiply",
              path: "substitutionRateSdf",
              unit: "",
              decimal: 1,
            },
            {
              operator: "multiply",
              path: "avgCostSdf",
              unit: "$",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Fillings x Reduction x Substitution Rate x Cost",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: 2,
            },
            {
              operator: "subtract",
              prior: 1,
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Savings - Substitution Cost",
      },
    ],
    notes:
      "The fillings price is on the low side because kids' cavities can cost a lot more. There is no good data on this. There are 200,000 dentists in the US. If each fills 4 cavities a day on 200 workdays, that's 800 cavities/year, or 160,000,000 in total. The estimate we use is much lower.  It is possible that more teeth get treated with SDF than just the ones that would have been filled, due to the ease of treatment. This will prevent much greater bills down the road. We are assuming 10% more. A quarter of adults have untreated cavities.",
    sources: [
      {
        name: "NIH",
        url: "https://www.nidcr.nih.gov/research/data-statistics/dental-caries/adults",
      },
    ],
  },
  {
    type: "form",
    title: "ER Billing",
    label: "ER Billing",
    headline:
      "Quizzify's unique solution to avoid surprise ER bills for non-elective care has been featured in the New York Times.",
    categoryKey: "surpriseBills",
    videoUrl: "https://www.youtube.com/embed/AtWriflrX2A",
    caption: "er billing",
    quizUrl: "https://play.quizzify.com/quiz/5376",
    fields: [
      {
        type: "sectionHeader",
        text: "ER Billing",
      },
      {
        headline: "Events per 1,000",
        name: "erVisitRate",
        specialInput: "number",
        width: 6,
      },
      {
        headline: "Current cost",
        name: "currentCost",
        specialInput: "$",
        width: 6,
      },
      {
        headline: "Our cost",
        name: "ourCost",
        specialInput: "$",
        width: 6,
      },
      {
        headline: "Success rate",
        name: "successRate",
        specialInput: "%",
        width: 6,
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Total ER Visits",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "surpriseBills.erVisitRate",
              unit: "per thousand",
            },
          ],
        },
        workCaption: "Population x ER Visit Rate",
      },
      {
        type: "lineItem",
        label: "Average Savings",
        value: {
          formula: [
            {
              first: true,
              path: "surpriseBills.currentCost",
              unit: "$",
            },
            {
              operator: "subtract",
              path: "surpriseBills.ourCost",
              unit: "$",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Population x ER Visit Rate",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: 2,
            },
            {
              operator: "multiply",
              prior: 1,
            },
            {
              operator: "multiply",
              path: "surpriseBills.successRate",
              unit: "%",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Visits x Savings per Visit x Success Rate",
      },
    ],
    sources: [
      {
        name: "Testimonials on Quizzify Emergency Consent",
        url: "https://www.quizzify.com/testimonials",
      },
      {
        name: "More Info on Our Emergency Consent",
        url: "https://www.quizzify.com/emergency-consent",
      },
      {
        name: "Health System Tracker",
        url: "https://www.healthsystemtracker.org/brief/an-examination-of-surprise-medical-bills-and-proposals-to-protect-consumers-from-them-3/#:~:text=Our%20analysis%20of%20large%20employer,of%20in%2Dnetwork%20inpatient%20stays.",
      },
      {
        name: "New York Times",
        url: "https://www.nytimes.com/2020/02/06/well/a-possible-strategy-for-fending-off-surprise-medical-bills.html",
      },
    ],
  },
  // {
  //   type: "form",
  //   label: "ERs",
  //   headline:
  //     "Quizzify educates on the clear cost and scope differences among free-standing ERs, ERs, and urgent care.",
  //   videoUrl: "https://youtube.com/embed/lsmlqU7casg",
  //   caption: "ERs",
  //   quizUrl: "https://play.quizzify.com/quiz/5375",
  //   fields: [
  //     {
  //       type: "sectionHeader",
  //       text: "ER Visits",
  //       named: "er-visits-header",
  //     },
  //     {
  //       headline: "Average cost",
  //       name: "avgCostERVisit",
  //       specialInput: "$",
  //       width: 6,
  //     },
  //     {
  //       headline: "Events per 1,000",
  //       name: "erVisitsPerThousand",
  //       specialInput: "number",
  //       width: 6,
  //     },
  //     {
  //       type: "divider",
  //       nae: "scan-divider",
  //     },
  //     {
  //       type: "sectionHeader",
  //       text: "Urgent Care",
  //       name: "urgent-care-header",
  //     },
  //     {
  //       headline: "Average cost",
  //       name: "avgCostUrgentCare",
  //       specialInput: "$",
  //       width: 6,
  //     },
  //     {
  //       headline: "ER visits converted to urgent care by Quizzify",
  //       name: "erReduction",
  //       specialInput: "%",
  //       width: 6,
  //     },
  //   ],
  //   sources: [
  //     {
  //       name: "United Health",
  //       url: "https://www.unitedhealthgroup.com/newsroom/posts/2019-07-22-high-cost-emergency-department-visits.html",
  //     },
  //     {
  //       name: "CDC",
  //       url: "https://www.cdc.gov/nchs/fastats/emergency-department.htm",
  //     },
  //   ],
  // },
  {
    type: "form",
    label: "Premature Births",
    headline:
      "Quizzify teaches employees about the new blood test to predict premature births with high accuracy. Once predicted, well-established protocols are shown to prevent many of them.",
    categoryKey: "prematureBirths",
    videoUrl: "https://youtube.com/embed/iSz8Qh72Mes",
    caption: "premature births",
    quizUrl: "https://play.quizzify.com/quiz/5374",
    fields: [
      {
        type: "sectionHeader",
        text: "Premature Births",
      },
      {
        headline: "Birth Rate",
        name: "birthRatePerc",
        specialInput: "%",
        width: 6,
      },
      {
        headline: "Premature Rate",
        name: "prematureBirthRatePerc",
        specialInput: "%",
        width: 6,
      },
      {
        headline: "Cost of a NICU Stay",
        name: "costPer",
        specialInput: "$",
        width: 6,
      },
      {
        headline: "Quizzify Reduction",
        name: "reduction",
        specialInput: "%",
        width: 6,
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Total Premature Births",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "prematureBirths.birthRatePerc",
              unit: "%",
            },
            {
              operator: "multiply",
              path: "prematureBirths.prematureBirthRatePerc",
              unit: "%",
            },
          ],
        },
        workCaption: "Population x Birth Rate x Premature Rate",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
            },
            {
              operator: "multiply",
              path: "prematureBirths.costPer",
              unit: "$",
            },
            {
              operator: "multiply",
              path: "prematureBirths.reduction",
              unit: "%",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Premature Births x Cost x Reduction",
      },
    ],
    notes:
      "The Quizzify reduction - 10% - is the minimum reduction guaranteed by our partner, Sera Prognostics.",
    sources: [
      {
        name: "CDC",
        url: "https://www.cdc.gov/reproductivehealth/features/premature-birth/index.html",
      },
      {
        name: "Quizzify Webinar",
        url: "https://www.youtube.com/watch?v=BgkmPt7JmN0",
      },
    ],
  },
  {
    type: "form",
    label: "Scans",
    headline:
      'America leads the world by far in scans. We teach employees to ask the key questions...and not to "demand" inappropriate ones.',
    videoUrl: "https://www.youtube.com/embed/n7xoUq824Fw",
    caption: "scans",
    quizUrl: "https://play.quizzify.com/quiz/5377",
    fields: [
      {
        type: "sectionHeader",
        text: "CT Scans",
      },
      {
        headline: "Average cost",
        name: "avgCostCTScan",
        specialInput: "$",
        width: 4,
      },
      {
        headline: "Events per 1,000",
        name: "ctScansPerThousand",
        specialInput: "number",
        width: 4,
      },
      {
        headline: "Quizzify Reduction",
        name: "ctScanReduction",
        specialInput: "%",
        width: 4,
      },
      {
        type: "divider",
        key: "scan-divider",
      },
      {
        type: "sectionHeader",
        text: "MRIs",
        subtext:
          "MRIs have hazards most people are unaware of. We teach employees to ask about them.",
      },
      {
        headline: "Average cost",
        name: "avgCostMRI",
        specialInput: "$",
        width: 4,
      },
      {
        headline: "Events per 1,000",
        name: "mrisPerThousand",
        specialInput: "number",
        width: 4,
      },
      {
        headline: "Quizzify Reduction",
        name: "mriReduction",
        specialInput: "%",
        width: 4,
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Baseline CT Scans",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "ctScansPerThousand",
              unit: "per thousand",
            },
          ],
        },
        workCaption: "Population x CT Scan Rate",
      },
      {
        type: "lineItem",
        label: "CT Savings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
            },
            {
              operator: "multiply",
              path: "ctScanReduction",
              unit: "%",
            },
            {
              operator: "multiply",
              path: "avgCostCTScan",
              unit: "$",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "CT Scans x Reduction x Cost",
      },
      {
        type: "lineItem",
        label: "Baseline MRIs",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "mrisPerThousand",
              unit: "per thousand",
            },
          ],
        },
        workCaption: "Population x MRI Rate",
      },
      {
        type: "lineItem",
        label: "MRI Savings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
            },
            {
              operator: "multiply",
              path: "mriReduction",
              unit: "%",
            },
            {
              operator: "multiply",
              path: "avgCostMRI",
              unit: "$",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "MRIs x Reduction x Cost",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: 3,
            },
            {
              operator: "add",
              prior: 1,
            },
          ],
          resultUnit: "$",
        },
        workCaption: "CT + MRI Savings",
      },
    ],
    notes:
      "For MRIs and CT Scans there is a wide range of costs for both, and we chose defaults that are below average.",

    sources: [
      {
        name: "New Choice Health",
        url: "https://www.newchoicehealth.com/ct-scan/cost#:~:text=The%20average%20cost%20of%20a,or%20an%20outpatient%20surgery%20center",
      },
      {
        name: "Washington Post",
        url: "https://www.washingtonpost.com/national/health-science/jama-opinion-piece-slams-our-addiction-to-unnecessary-mris-ct-scans/2019/01/18/d1f5b2f4-19be-11e9-88fe-f9f77a3bcb6c_story.html",
      },
      {
        name: "GE Healthcare",
        url: "https://www.gehealthcare.com/feature-article/how-much-does-an-mri-cost",
      },
    ],
  },
  {
    type: "form",
    label: "Spinal Fusion",
    headline:
      'Spinal fusions are the riskiest major surgery. We teach people about risks and alternatives. Our back pain quiz is "self-preauthorization."',
    videoUrl: "https://youtube.com/embed/oJFxuYjOFdA",
    caption: "spinal fusions",
    quizUrl: "https://play.quizzify.com/quiz/5378",
    fields: [
      {
        type: "sectionHeader",
        key: "spinal-fusions-section-header",
        text: "Spinal Fusions",
      },
      {
        headline: "Average cost",
        name: "avgCostSpinalFusion",
        specialInput: "$",
        width: 4,
      },
      {
        headline: "Events per 1,000",
        name: "spinalFusionsPerThousand",
        specialInput: "number",
        width: 4,
      },
      {
        headline: "Quizzify Reduction",
        name: "spinalFusionReduction",
        specialInput: "%",
        width: 4,
      },
    ],
    sources: [
      {
        name: "Modern Healthcare",
        url: "https://www.modernhealthcare.com/article/20180827/NEWS/180829918/health-insurers-look-for-ways-to-cut-costs-for-back-surgery",
      },
      {
        name: "Becker's Spine Review",
        url: "https://www.beckersspine.com/spine/item/45273-surgeons-perform-1-62m-instrumented-spinal-fusions-per-year-in-the-us-5-things-to-know.html#:~:text=Careers%20at%20Becker's-,Surgeons%20perform%201.62M%20instrumented%20spinal%20fusions%20per%20year,US%3A%205%20things%20to%20know&text=There%20are%20more%20than%201,to%20an%20iData%20Research%20report.",
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Baseline Spinal Fusions",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "spinalFusionsPerThousand",
              unit: "per thousand",
              decimal: 1,
            },
          ],
        },
        workCaption: "Population x Rate",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
              decimal: 1,
            },
            {
              operator: "multiply",
              path: "avgCostSpinalFusion",
              unit: "$",
            },
            {
              operator: "multiply",
              path: "spinalFusionReduction",
              unit: "%",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Fusions x Cost x Reduction",
      },
    ],
  },
  {
    type: "form",
    label: "Stents",
    headline:
      "Reams of literature on stents show that after 6 months, there is zero improvement in outcomes for the #1 indication (stable angina, one vessel). We educate on alternatives.",
    videoUrl: "https://youtube.com/embed/qRlf_-oyfWo",
    caption: "stents",
    quizUrl: "https://play.quizzify.com/quiz/5373",
    fields: [
      {
        type: "sectionHeader",
        key: "stents-section-header",
        text: "Stents",
      },
      {
        headline: "Average cost",
        name: "avgCostStent",
        specialInput: "$",
        width: 4,
      },
      {
        headline: "Events per 1,000",
        name: "stentsPerThousand",
        specialInput: "number",
        width: 4,
      },
      {
        headline: "Quizzify Reduction",
        name: "stentsReduction",
        specialInput: "%",
        width: 4,
      },
    ],
    calculations: [
      {
        type: "lineItem",
        label: "Baseline Stents",
        value: {
          formula: [
            {
              first: true,
              path: "numPeople",
            },
            {
              operator: "multiply",
              path: "stentsPerThousand",
              unit: "per thousand",
            },
          ],
        },
        workCaption: "Population x Rate",
      },
      {
        type: "total",
        label: "Total Savings",
        value: {
          formula: [
            {
              first: true,
              prior: true,
            },
            {
              operator: "multiply",
              path: "avgCostStent",
              unit: "$",
            },
            {
              operator: "multiply",
              path: "stentsReduction",
              unit: "%",
            },
          ],
          resultUnit: "$",
        },
        workCaption: "Events x Cost x Reduction",
      },
    ],
    notes: "Over 965,000 stents are performed on the 270,000,000 US adults.",
    sources: [
      {
        name: "Chicago Tribune",
        url: "https://www.chicagotribune.com/lifestyles/health/health-fitness/sns-united-states-angioplasty-cost-20191227-fmbdrghgezazlfh2t6fg5r7ila-story.html",
      },
    ],
  },
  {
    label: "ROI",
    type: "summary",
  },
];

export default steps;
