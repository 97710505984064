import {
  Typography,
  Divider,
  Table,
  TableRow,
  TableCell,
  TableBody,
  useMediaQuery,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import _ from "lodash";

import { getValue } from "./calc";

const getSuffix = (unit) => {
  if (unit === "%") return "%";
  if (unit === "per thousand") return " per thousand";
  return "";
};

const getPrefix = (unit) => (unit === "$" ? "$" : "");

const getCurrentValue = (values, calculations, index, cur) => {
  const decimal = cur.decimal || 0;

  if (cur.prior) {
    const unit = calculations[index - cur.prior].value.resultUnit;

    return (
      <NumberFormat
        value={getValue(
          values,
          _.get(calculations, `[${index - cur.prior}]`, {}).value,
          calculations,
          index - cur.prior
        ).toFixed(decimal)}
        displayType={"text"}
        thousandSeparator={true}
        suffix={getSuffix(unit)}
        prefix={getPrefix(unit)}
      />
    );
  }

  return (
    <NumberFormat
      value={Number(_.get(values, cur.path) || cur.value || -999).toFixed(
        decimal
      )}
      displayType={"text"}
      thousandSeparator={true}
      suffix={getSuffix(cur.unit)}
      prefix={getPrefix(cur.unit)}
    />
  );
};

const getWork = (values, value, calculations, index) => {
  const { formula } = value;

  const getOperator = (operator) => {
    if (operator === "multiply") return "x";
    if (operator === "divide") return "/";
    if (operator === "subtract") return "-";
    return "+";
  };

  const getComponent = (cur) => {
    const currentValue = getCurrentValue(values, calculations, index, cur);

    if (cur.first) {
      return currentValue;
    }

    return (
      <>
        {" "}
        {getOperator(cur.operator)} {currentValue}
      </>
    );
  };

  const total = formula.map((cur) => (
    <span
      key={`${cur.path}-${cur.operator}-${getCurrentValue(
        values,
        calculations,
        index,
        cur
      )}`}
    >
      {getComponent(cur)}
    </span>
  ));

  return <>{total}</>;
};

const Calculations = ({ values, calculations }) => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  if (!calculations) return null;

  return (
    <>
      <Divider style={{ marginTop: 12, width: "100%" }} />
      <Table size="small">
        <TableBody>
          {calculations.map((calc, index) => {
            console.log(
              `${calc.label}: ${getValue(
                values,
                calc.value,
                calculations,
                index
              )}`
            );
            return (
              <TableRow key={calc.label}>
                <TableCell>{calc.label}</TableCell>
                <TableCell>
                  <Typography variant={calc.type === "total" ? "h6" : "body1"}>
                    <NumberFormat
                      value={Math.round(
                        getValue(
                          values,
                          calc.value,
                          calculations,
                          index,
                          calc.label === "Total Savings"
                        )
                      )}
                      displayType={"text"}
                      thousandSeparator={true}
                      suffix={getSuffix(calc.value.resultUnit)}
                      prefix={getPrefix(calc.value.resultUnit)}
                    />
                  </Typography>
                  {isSmallScreen && (
                    <Typography variant="caption">
                      {calc.workCaption}
                      <Typography>
                        {getWork(values, calc.value, calculations, index)}
                      </Typography>
                    </Typography>
                  )}
                </TableCell>
                {!isSmallScreen && (
                  <TableCell>
                    <Typography variant="caption">
                      {calc.workCaption}
                    </Typography>
                    <Typography>
                      {getWork(values, calc.value, calculations, index)}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};

export default Calculations;
